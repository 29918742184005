import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import "./style.scss"
//
const Button = styled(Link)`
  /* background: #1d4d7a; */
  border: none;
  background: ${props => (props.white ? "#fff" : "#1d4d7a")};
  color: ${props => (props.white ? "#1d4d7a" : "#fff")};
  width: fit-content;
  padding: 0.3em 1em;
  font-weight: 600;
  transition: 0.25s;
  &:hover,
  &:focus {
    outline: none;
    box-shadow: inset 7.6em 0 0 0 ${props => (props.white ? "#1d4d7a" : "#fff")};
    color: ${props => (props.white ? "#fff" : "#1d4d7a")};
  }
`
const WrapBtn = styled.div`
  display: flex;
  justify-content: ${props => (props.align === "left" ? "left;" : "flex-end;")};
  width: 100%;
`
//
function DetailsButton({
  children = "Кнопка",
  className,
  white,
  style,
  align = "left",
  link = "/",
}) {
  return (
    <WrapBtn align={align} style={style}>
      <Button className={className} white={white} to={link}>
        {children}
      </Button>
    </WrapBtn>
  )
}

export default DetailsButton
