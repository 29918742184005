import React from "react"
import DetailsButton from "src/components/buttons/details"
import styled from "styled-components"
import FormContact from "src/components/form-contact/index"
//
const Wrapper = styled.div`
  max-width: 70%;
`
const Delimiter = styled.div`
  border: none;
  border-top: 1px solid #fff;
  max-width: 15em;
`
const Title = styled.div`
  font-size: 2rem;
`
const Desc = styled.div`
  margin: 2em 0;
  font-size: 1.2rem;
`
const ServiceBg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 92, 149, 0.8);
  padding: 3em 0;
  min-height: inherit;
`
//
function Service({
  children,
  title,
  desc,
  descSec,
  btn,
  className,
  linkButton,
  descButton,
  feedbackForm,
}) {
  return (
    <ServiceBg className={className}>
      <Wrapper>
        {title && <Title>{title}</Title>}
        {desc && <Desc>{desc}</Desc>}
        {descSec && (
          <>
            <Delimiter /> <Desc>{descSec}</Desc>
          </>
        )}
        {btn && (
          <DetailsButton white="true" link={linkButton}>
            {descButton ? descButton : "Подробнее"}
          </DetailsButton>
        )}
        {children}
        {feedbackForm && <FormContact ticketType="development" />}
      </Wrapper>
    </ServiceBg>
  )
}

export default Service
