import React from "react"
import styled from "styled-components"
import axios from "axios"
import Recaptcha from "react-recaptcha"
import DetailsButton from "src/components/buttons/details"
import { Helmet } from "react-helmet"

const FormElem = styled("div")`
  margin-right: auto;
  margin-left: auto;
  margin-top: 1.5em;
  display: ${props => (props.isShow ? "flex" : "none")};
`

const Response = styled.div`
  display: ${props => (props.isShow ? "none" : "flex")};
  justify-content: center;
  margin: 3em 0;
`

const Counter = styled("div")`
  float: right;
  padding: 0.1rem 0 0 0;
  font-size: 0.875rem;
  color: #333;
`

const FormButton = styled("button")`
  margin: 1em 0;
  width: 100%;
`

const Form = styled("form")`
  margin: 1em 0;
`

const ErrorDiv = styled.div`
  min-height: 1rem;
  font-size: 0.9rem;
  color: #e34334;
`

let recaptchaInstance
class FormContact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      author: "",
      phone: "",
      contact: "",
      position: "",
      question: "",
      response: "",
      ticket_type: this.props.ticketType,
      formErrors: { author: "", contact: "" },
      nameValid: false,
      contactValid: false,
      questionValid: false,
      formValid: false,
      isVerified: false,
      chars_count: 0,
      max_chars: 2000,
      isShow: true,
      isResponseError: false,
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    axios({
      method: "POST",
      url: "https://crm.novawardtech.ru/newticket",
      data: {
        name: this.state.author,
        position: this.state.position,
        phone: this.state.phone,
        contact: this.state.contact,
        question: this.state.question,
        ticket_type: this.state.ticket_type,
        response: this.state.response,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.data === "success") {
          this.setState({ isShow: false })
          this.resetForm()
        } else if (response.data === "fail") {
          this.setState({ isShow: false, isResponseError: true })
          this.resetForm()
        }
      })
      .catch(error => {
        // console.log("error", error)
      })
  }

  resetForm() {
    this.setState({
      author: "",
      contact: "",
      phone: "",
      position: "",
      question: "",
      response: "",
    })
    recaptchaInstance.reset()
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors
    let authorValid = this.state.authorValid
    let positionValid = this.state.positionValid
    let phoneValid = this.state.phoneValid
    switch (fieldName) {
      case "phone":
        phoneValid = value.match(/^[0-9\\+]+$/)
        fieldValidationErrors.phone = phoneValid
          ? ""
          : "Может содержать только цифры и +"
        break
      default:
        break
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        authorValid: authorValid,
        positionValid: positionValid,
      },
      this.validateForm
    )
  }
  validateForm() {
    this.setState({
      formValid:
        this.state.authorValid &&
        this.state.positionValid &&
        this.state.phoneValid,
    })
  }

  recaptchaLoaded() {
    // console.log("captcha successfully")
  }

  verifyCallback = function (response) {
    this.setState({ response: response, isVerified: true })
  }

  handleInput(e) {
    const id = e.target.id
    const value = e.target.value
    this.setState({ [id]: value }, () => {
      this.validateField(id, value)
    })
  }

  handleTextArea(e) {
    this.handleInput(e)
    const charCount = e.target.value.length
    const maxChar = this.state.max_chars
    const charLength = maxChar - charCount
    this.setState({ chars_count: charCount })
  }

  render() {
    return (
      <>
        <Helmet>
          <script
            src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
            async
            defer
          ></script>
        </Helmet>
        <Response isShow={this.state.isShow}>
          <h6>
            {this.state.isResponseError ? (
              <>
                Ошибка отправки формы попробуйте написать нам в телеграм.
                <DetailsButton
                  style={{ justifyContent: "center", marginTop: "1em" }}
                  link="https://t.me/novawardtech"
                >
                  Telegram
                </DetailsButton>
              </>
            ) : (
              <>
                Благодарим за проявленный интерес, мы свяжемся с вами в
                ближайшее время.
              </>
            )}
          </h6>
        </Response>
        <FormElem className="container row" isShow={this.state.isShow}>
          <Form
            id="contact-form"
            onSubmit={this.handleSubmit.bind(this)}
            method="POST"
          >
            <div className="panel panel-default">
            </div>
            <div className="form-group">
              <label htmlFor="author">Имя и название компании*</label>
              <input
                type="text"
                required
                className="form-control"
                id="author"
                value={this.state.author}
                onChange={this.handleInput.bind(this)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Должность</label>
              <input
                type="text"
                className="form-control"
                id="position"
                value={this.state.position}
                onChange={this.handleInput.bind(this)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Телефон*</label>
              <input
                type="text"
                placeholder="+7"
                className="form-control"
                required
                id="phone"
                value={this.state.phone}
                onChange={this.handleInput.bind(this)}
              />
              <ErrorDiv>
                {this.state.formErrors.phone
                  ? this.state.formErrors.phone
                  : " "}
              </ErrorDiv>
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Контакт для связи</label>
              <input
                type="text"
                className="form-control"
                id="contact"
                value={this.state.contact}
                onChange={this.handleInput.bind(this)}
              />
            </div>
            {this.props.fieldQuestion && (
              <div>
                <div className="form-group">
                  <label htmlFor="message">Вопрос*</label>
                  <textarea
                    className="form-control"
                    maxLength={this.state.max_chars}
                    required
                    rows="5"
                    id="question"
                    value={this.state.question}
                    onChange={this.handleTextArea.bind(this)}
                  />
                  <Counter>
                    <span id="current">{this.state.chars_count}</span>
                    <span id="maximum">/{this.state.max_chars}</span>
                  </Counter>
                </div>
              </div>
            )}
            <Recaptcha
              ref={e => (recaptchaInstance = e)}
              sitekey="6Ldb-DcaAAAAAN8GAt-_BjR6ONbLQ3oDh5lqmsHd"
              render="explicit"
              verifyCallback={this.verifyCallback.bind(this)}
              onloadCallback={this.recaptchaLoaded.bind(this)}
            />
            <FormButton
              type="submit"
              className="btn btn-primary btn-lg"
              disabled={!this.state.isVerified}
            >
              Отправить
            </FormButton>
          </Form>
        </FormElem>
      </>
    )
  }
}

export default FormContact
